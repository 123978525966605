import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../../axiosInstance';
import { Container, Table, Pagination, Row, Col, Button } from 'react-bootstrap';
import moment from 'moment';

function WeeklyTaskList() {
    const navigate = useNavigate();
    const [tasks, setTasks] = useState([]);
    const [error, setError] = useState('');
    const [weekOffset, setWeekOffset] = useState(0); // Permet de naviguer entre les semaines

    useEffect(() => {
        fetchTasks();
    }, [weekOffset]);

    const fetchTasks = async () => {
        try {
            const response = await axiosInstance.get('/tasks/', {
                params: {
                    status: 'start', // On filtre uniquement les tâches avec le statut "start"
                }
            });

            setTasks(response.data.results || []);
            setError('');
        } catch (err) {
            setError('Erreur lors de la récupération des tâches');
            console.error('Fetch tasks error:', err);
        }
    };

    // Fonction pour filtrer les tâches de la semaine sélectionnée
    const getTasksForCurrentWeek = () => {
        const startOfWeek = moment().startOf('isoWeek').add(weekOffset, 'weeks');
        const endOfWeek = moment(startOfWeek).endOf('isoWeek');

        return tasks.filter(task => {
            const taskDate = moment(task.review_at);
            return taskDate.isBetween(startOfWeek, endOfWeek, null, '[]'); // Inclut les bornes
        });
    };

    const currentWeekTasks = getTasksForCurrentWeek();
    const startOfWeekFormatted = moment().startOf('isoWeek').add(weekOffset, 'weeks').format('DD/MM/YYYY');
    const endOfWeekFormatted = moment().endOf('isoWeek').add(weekOffset, 'weeks').format('DD/MM/YYYY');

    const handleEditClick = (expertiseId) => {
        navigate(`/edit-expertise/${expertiseId}`);
    };
    return (
        <Container fluid>
            <h2 className="text-center">Tâches de la semaine {startOfWeekFormatted} - {endOfWeekFormatted}</h2>

            <Row className="mb-3">
                <Col className="text-center">
                    <Button variant="secondary" onClick={() => setWeekOffset(weekOffset - 1)}>← Semaine précédente</Button>
                    {' '}
                    <Button variant="secondary" onClick={() => setWeekOffset(weekOffset + 1)}>Semaine suivante →</Button>
                </Col>
            </Row>

            {currentWeekTasks.length === 0 ? (
                <p className="text-center">Aucune tâche pour cette semaine.</p>
            ) : (
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Titre</th>
                            <th>Titulaire</th>
                            <th>Date de Révision</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentWeekTasks.map(task => (
                            <tr key={task.id}>
                                <td>{task.title}</td>
                                <td>{task.receiver}</td>
                                <td>{moment(task.review_at).format('DD/MM/YYYY')}</td>
                                <td><Button variant="primary" onClick={() => handleEditClick(task.expertise)}>Gestion</Button></td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
        </Container>
    );
}

export default WeeklyTaskList;
